// extracted by mini-css-extract-plugin
export var buttonList = "styles-module--buttonList--HTrvb";
export var flex = "styles-module--flex--N7rC8";
export var flexList = "styles-module--flexList--Mqfns";
export var heading = "styles-module--heading--RVjtI";
export var image = "styles-module--image--X86+v";
export var imageContainer = "styles-module--imageContainer--6F6mz";
export var section = "styles-module--section--CfGB6";
export var statLabel = "styles-module--statLabel--Yeqk5";
export var statValue = "styles-module--statValue--bAT8E";
export var text = "styles-module--text--GwM6a";