// extracted by mini-css-extract-plugin
export var author = "styles-module--author--H5l0B";
export var authorContainer = "styles-module--authorContainer--fBhyI";
export var blockquote = "styles-module--blockquote--1MwqY";
export var box = "styles-module--box--8cSgc";
export var flex = "styles-module--flex--rmp8v";
export var flexList = "styles-module--flexList--44RNe";
export var heading = "styles-module--heading--HhqEf";
export var kicker = "styles-module--kicker--UaC4J";
export var section = "styles-module--section--tbW-O";
export var text = "styles-module--text--zYcFG";