// extracted by mini-css-extract-plugin
export var button = "styles-module--button--Jkkn2";
export var buttonContainer = "styles-module--buttonContainer--KI+ef";
export var container = "styles-module--container--JWcKa";
export var content = "styles-module--content--5TdUl";
export var contentWrapper = "styles-module--contentWrapper--iguc8";
export var heroImage = "styles-module--heroImage--UqLAy";
export var overlay = "styles-module--overlay--SCUwd";
export var section = "styles-module--section--iDF0x";
export var typo1 = "styles-module--typo1--t0lty";
export var typo2 = "styles-module--typo2---VaLO";
export var typo3 = "styles-module--typo3--JnPo8";